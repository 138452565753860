import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import SmartTable from 'vuejs-smart-table'
import VueTippy from 'vue-tippy'

import { state } from './store/store.js';
import 'tippy.js/dist/tippy.css'

// Components
// // import TextInput from '@/components/TextInput.vue'
// import { RecycleScroller } from 'vue-virtual-scroller'
// import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

(async function() {
	try {
		state.user = await state.api.call('getUserInfo');
		state.user.smsAllowed = state.user.smsAllowed === 1 ? true:false;
		state.user.agreementSigned = state.user.agreementSigned === 1 ? true : false;
		console.log(state.user);
	} catch (e) {
		console.error(e);
	}

	const app = createApp(App);
	app.use(SmartTable);
	app.use(
		VueTippy,
		{
			directive: 'tippy', // => v-tippy
			component: 'tippy', // => <tippy/>
			componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
			defaultProps: {
				placement: 'auto-end',
				allowHTML: true,
				arrow: true
			}, // => Global default options * see all props
		}
	)
	app.use(router);
	
	app.mount('#app');
})();
