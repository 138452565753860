<script setup>
import { state } from '../store/store.js';
</script>

<script>
    function round(value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals);
    }
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    export default {
        name: 'MyPerformance',
        components: {},
        data() {
            return {
              leadTypes: ['VF','VF0','V1','V2','V3','VB1', 'VB2', 'VB3'],
              ratioActiveType: 'VF',
              aplActiveType: 'VF',
              closingRatio: {
                ratio: 0,
                sold: 0,
                unsold: 0,
              },
              apl: {
                average: 0,
                total: 0,
                leadCount: 0,
              }
            };
        },
        async mounted() {
            try {
                state.currentView = 'my-performance';
                await this.setClosingRatio();
                await this.setAPL();
            } catch (error) {
                console.error(error);
            }
        },
        watch: {
          async ratioActiveType() {
            await this.setClosingRatio();
          },
          async aplActiveType() {
            await this.setAPL();
          }
        },
        methods: {
          formatDecimal(num) {
            return round(num, 2);
          },
          formatPercentage(num) {
            return this.formatDecimal(num*100);
          },
          formatMoney(num) {
            return USDollar.format(num);
          },
          async setClosingRatio() {
            let ratio = await state.api.call("getStat", {
              customerID: state.user.rid,
              type: 'closing',
              leadType: this.ratioActiveType
            });
            this.closingRatio = ratio.stat;
          },
          async setAPL() {
            let apl = await state.api.call("getStat", {
              customerID: state.user.rid,
              type: 'apl',
              leadType: this.aplActiveType
            });
            this.apl = apl.stat;
          }
        }
    }
</script>

<template>
  <div class="container" id="myPerformance">
        <div class="title">
            My Performance
        </div>
        <div class="_stats">
          <div>
            <h3>Closing Ratio</h3>
            <select v-model="ratioActiveType">
              <template v-for="lead in leadTypes" :key="lead">
                <option v-if="lead == ratioActiveType" selected :value="lead">{{ lead }} Leads</option>
								<option v-else :value="lead">{{ lead }} Leads</option>
              </template>
            </select>
            <h2>{{ formatPercentage(closingRatio.ratio) }}% <span>{{ ratioActiveType }} Leads ({{ closingRatio.sold }} / {{ closingRatio.unsold }})</span></h2>
            <p><em>This is a ratio of your <strong>SOLD</strong> leads vs. your <strong>UNSOLD</strong> leads.</em></p>
            <div class="_benchmarks">
              <h4>Victory Benchmarks:</h4>
              <ul>
                <li><u>30-35%</u> on <strong>VF Leads</strong></li>
                <li><u>5-10%</u> on <strong>all other lead types</strong></li>
              </ul>
            </div>
          </div>
          <div>
            <h3>APL</h3>

            <select v-model="aplActiveType">
              <template v-for="lead in leadTypes" :key="lead">
                <option v-if="lead == aplActiveType" selected :value="lead">{{ lead }} Leads</option>
								<option v-else :value="lead">{{ lead }} Leads</option>
              </template>
            </select>
            <h2>{{ formatMoney(apl.average) }} <span>{{ aplActiveType }} Leads ({{ formatMoney(apl.total) }} / {{ apl.leadCount }})</span></h2>

            <p><em>This is your <strong>Total Annual Premiums</strong> sold per <strong># of Sold Leads</strong></em></p>
            <div class="_benchmarks">
              <h4>Victory Benchmarks:</h4>
              <ul>
                <li><u>$250-$350</u> on <strong>VF Leads</strong></li>
                <li><u>$50-$100</u> on <strong>all other lead types</strong></li>
              </ul>
            </div>
          </div>
        </div>
    </div>
</template>

<style scoped>
  .container {
      display: flex;
      padding: 24px 24px 0;
      flex-wrap: wrap;
      flex-grow: initial;
  }
  .title {
      color: var(--btn-blue-dark);
      font-weight: 300;
      font-size: 28px;
      line-height: 32px;
      text-align: left;
  }
  ._stats {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: 20px;
    
    & * {
      -webkit-box-sizing: inherit;
      box-sizing: inherit;
    }

    & > div {
      width: calc(50%);
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 30px 30px;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      &:first-of-type {
        border-right: 1px solid rgba(0,0,0,0.1);

          @media screen and (max-width: 768px) {
            border-right: 0;
            border-bottom: 1px solid rgba(0,0,0,0.1);
          }
      }
    }
  }
  h3 {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  h2 {
    display: block;
    width: 50%;
    text-align: center;
    font-size: 46px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 20px;
    color: var(--btn-blue-light);
    padding: 20px;

    &:first-of-type {
      width: 100%;
      font-size: 60px;
    }

    @media screen and (max-width: 1080px) {
      width: 100%;
    }

    & span {
      width: 100%;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      color: var(--btn-blue-dark);
      margin-top: 10px;
      display: block !important;
      font-style: italic;
      line-height: 1.3;
    }
  }
  h4 {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    margin-top: 0;
    text-transform: uppercase;
    color: rgba(255,255,255,0.9);
  }
  p {
    font-size: 16px;
    text-align: center;
    width: 100%;
    line-height: 1.3;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
  }
  li {
    padding: 0;
    margin: 5px 0;
    line-height: 1.3;
  }
  ._benchmarks {
    display: block;
    margin: 40px auto 0;
    max-width: 600px;
    padding: 25px 30px;
    border-radius: 10px;
    background-color: var(--btn-blue-light);

    p, ul {
      color: #fff;
    }
  }
</style>