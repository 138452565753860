<script setup>
    import { state } from '../store/store.js';
    // const props = defineProps(['filled'])
</script>

<script>
	export default {
		name: 'Agreement',
        props: {
            filled: Boolean, 
            blocking: Boolean
        },
		mounted() {
            this.emitInterface();
            //Set Date
            let today = new Date(),
                year = today.getFullYear(),
                month = String(today.getMonth() + 1).padStart(2, '0'),
                day = String(today.getDate()).padStart(2, '0');

            this.date = `${month}-${day}-${year}`;

            if (this.filled) {
                let initials = Array.from(state.user.firstName)[0]+Array.from(state.user.lastName)[0],
                    signedDate = new Date(state.user.agreementSignedDate);
                this.date = `${signedDate.getMonth() + 1}-${signedDate.getDate()}-${signedDate.getFullYear()}`;
                this.initials = {
                    c1: initials,
                    c2: initials,
                    c3: initials,
                    c4: initials,
                    c5: initials,
                    c6: initials,
                    c7: initials,
                    c8: initials,
                    c9: initials,
                    c10: initials,
                    c11: initials,
                    c12: initials,
                    c13: initials,
                    c14: initials,
                    c15: initials,
                    c16: initials,
                    c17: initials,
                    c18: state.user.agreementSignedName,
                    c19: 'VICTORY MARKETING & DEVELOPMENT LLC'
                }
                this.readonly = true;
            }

            if (this.blocking) {
                document.querySelector('dialog._agreement').addEventListener('cancel', e => {
                    e.preventDefault();
                });
                document.querySelector('dialog._agreement').addEventListener('keydown', e => {
                    if (e.key === 'Escape') {
                        e.preventDefault();
                    }
                });
            }
		},
		data: () => ({
			npn: '',
            date: '',
            initials: {
                c1: '',
                c2: '',
                c3: '',
                c4: '',
                c5: '',
                c6: '',
                c7: '',
                c8: '',
                c9: '',
                c10: '',
                c11: '',
                c12: '',
                c13: '',
                c14: '',
                c15: '',
                c16: '',
                c17: '',
                c18: '',
                c19: 'VICTORY MARKETING & DEVELOPMENT LLC'
            },
            readonly: false
		}),
		methods: {
            emitInterface() {
                this.$emit("interface", {
                    showAgreement: (force = false) => this.openModal(force)
                });
            },
            openModal(force = false) {
                if (force) {
                    document.querySelector('dialog._agreement').showModal();
                }
                if (state.user.rid && !state.user.agreementSigned) {
                    document.querySelector('dialog._agreement').showModal();
                }else if (state.user.rid && state.user.agreementSigned) {
                    this.$emit('signed', 'true');
                }
            },
            closeModal() {
                document.querySelector('dialog._agreement').close();
            },
			async submitAgreement() {
                let notInitialed = false;
                for (let k in this.initials) {
                    if (!this.initials[k]) {
                        notInitialed = true;
                        state.flash({
                            type: 'error',
                            message: 'Please make sure you initial all fields'
                        });
                        break;
                    }
                }
                if (!notInitialed) {
					let user = await state.api.call("signLeadAgreement", {
						uid: state.user.rid,
                        name: this.initials.c18
					});
					document.querySelector('dialog._agreement').close();
					if (user && user.rid && user.agreementSigned) {
						state.user = user;
						state.flash({
							type: 'success',
							message: 'Agreement signed and confirmed.'
						});
                        this.$emit('signed', 'true');
					}else {
						state.flash({
							type: 'error',
							message: 'Something went wrong. Please refresh the page and try again.'
						});
					}
				}
			}
		},
	};
</script>

<template>
	<dialog class="_agreement">
		<div>
            <h2>Lead Service Agreement</h2>
			<p class="_note">Please review the agreement below. <span v-if="!readonly">To continue, we will need you to read and initial your name next to each clause of the agreement. You will only need to do this once for the duration that you use Victory Marketing services.</span></p>
            <p>This Lead Service Agreement is entered into as of <strong>{{ date }}</strong> by and between Victory Marketing & Development, LLC (“company”) and <strong>{{ state.user.firstName }} {{ state.user.lastName }}</strong> (“Agent”). Company and Agents are each referred to herein collectively as “The Parties.”</p>
            <p>Whereas Company is providing services pursuant to the agreed-upon terms with the Agent. Should the Agent wish to avail themselves of such services, and in consideration of the mutual terms, conditions, and covenant contained within the Agreement and set forth below, the Parties hereby agree to the following:</p>
            <form @submit.prevent="submitAgreement">
                <div class="input-group -sbs">
                    <input v-model="initials.c1" type="text" placeholder="Initials" required :disabled="readonly">
                    <p>Agent understands that leads are purchased by counties and state.</p>
                </div>
                <div class="input-group -sbs">
                    <input v-model="initials.c2" type="text" placeholder="Initials" required :disabled="readonly">
                    <p>Agent understands that they are committing to the purchase of every lead received from the counties ordered.</p>
                </div>
                <div class="input-group -sbs">
                    <input v-model="initials.c3" type="text" placeholder="Initials" required :disabled="readonly">
                    <p>Agent understands that they can cancel any counties ordered with a 30-day notice by email to Victory Marketing & Development, LLC with verification from Company of cancel date.</p>
                </div>
                <div class="input-group -sbs">
                    <input v-model="initials.c4" type="text" placeholder="Initials" required :disabled="readonly">
                    <p>Agent understands that the Company will deliver leads daily or as they come in from mailings. Company is not responsible for leads going to spam or “getting lost.”</p>
                </div>
                <div class="input-group -sbs">
                    <input v-model="initials.c5" type="text" placeholder="Initials" required :disabled="readonly">
                    <p>Agent understands that Lead Credit may be issued for “duplicate” leads only. Duplicate is defined as “any lead that has identical address, client name, and Victory Marketing Lead Code.” If Agent receives what Agent believes to be duplicate leads, Agent must send photographic evidence of BOTH leads, side-by-side, with clearly legible duplicate data, so Company may verify and issue Lead Credit as deemed appropriate.</p>
                </div>
                <div class="input-group -sbs">
                    <input v-model="initials.c6" type="text" placeholder="Initials" required :disabled="readonly">
                    <p>Agent understands this agreement is non-transferrable.</p>
                </div>
                <div class="input-group -sbs">
                    <input v-model="initials.c7" type="text" placeholder="Initials" required :disabled="readonly">
                    <p>Agent shall communicate with Company immediately upon discovery of any issues with the system or quality of the leads. Communication shall be in the form of written or electronic mail. We will accept phone notice followed by written communication.</p>
                </div>
                <div class="input-group -sbs">
                    <input v-model="initials.c8" type="text" placeholder="Initials" required :disabled="readonly">
                    <p>Agent agrees and guarantees to the Company that Agent as user, is a State Licensed Life or Life/Health insurance agent and is in Good Standing with Carriers represented by the Company and Agrees to market only Products and Carriers represented by the Company while working Leads provided by the Company unless given a waiver by Company.</p>
                </div>
                <div class="input-group -sbs">
                    <input v-model="initials.c9" type="text" placeholder="Initials" required :disabled="readonly">
                    <p>Agent understands that the price per lead is the “Current” published price set by the company on the date this agreement is signed, and that the price is subject to increase or decrease with 14 day notice by email by company.</p>
                </div>
                <div class="input-group -sbs">
                    <input v-model="initials.c10" type="text" placeholder="Initials" required :disabled="readonly">
                    <p>Agent understands they must have funds available each Friday evening to cover previous weeks lead flow. If a payment is returned for any reason, the Agent understands access will be halted until the payment has cleared. A fee of $25 will be assessed upon the occurrence. Any subsequent decline will incur a $50 service fee. The company has the right to waive any fees if it deems extenuating circumstances were cause.</p>
                </div>
                <div class="input-group -sbs">
                    <input v-model="initials.c11" type="text" placeholder="Initials" required :disabled="readonly">
                    <p>Agent understands that Victory Marketing does not guarantee any results in any percentage from any leads purchased, results depend on agent alone.</p>
                </div>
                <div class="input-group -sbs">
                    <input v-model="initials.c12" type="text" placeholder="Initials" required :disabled="readonly">
                    <p>Agent understands the Company reserves the right to cancel and or alter this agreement and end the business relationship at any time. Any update or alterations to the agreement shall be provided in writing within 14 days notice.</p>
                </div>
                <div class="input-group -sbs">
                    <input v-model="initials.c13" type="text" placeholder="Initials" required :disabled="readonly">
                    <p>Agent understands that he/she is responsible for knowing, understanding, and implementing the TCPA rules & regulations set in place by the federal government as it pertains to all purchases associated with Victory Marketing and Development, LLC.</p>
                </div>
                <div class="input-group -sbs">
                    <input v-model="initials.c14" type="text" placeholder="Initials" required :disabled="readonly">
                    <p>This Agreement shall be deemed to constitute a contract made and entered into under the laws of the State of Alabama, and for all purposes the Agreement and any ancillary documents shall be constructed and governed in accordance with the internal substantive laws of the State of Alabama. The Parties further agree that any proceeding arising out of this Agreement shall lie exclusively in the State Court of Alabama in and for Lee County, Alabama. The Parties hereby consent to jurisdiction and venue of the State Court.</p>
                </div>
                <div class="input-group -sbs">
                    <input v-model="initials.c15" type="text" placeholder="Initials" required :disabled="readonly">
                    <p>The Agent understands that the scope of their relationship with Company, they will be exposed to the Company’s confidential information. Such confidential information includes all material that has or could have commercial value or other utility in the business in which the Company is engaged. This includes any client lists, business documents, trade secrets, or other such confidential information. The Agent expressly agrees if exposed to any information as described above, to hold and maintain confidentiality in the strictest confidence for the sole and exclusive benefit of the Company. The Agent shall not use any such information for their own benefit, publish, copy, or otherwise disclose to others, or permit the use by others to the detriment of the Company, any confidential information. Should the Agent have reason to take any record, note, or other written, printed, or tangible material utilizing any confidential information, they expressly agree to return all such records to the Company upon the termination of this Agreement, and/or upon request by the Company.</p>
                </div>
                <div class="input-group -sbs">
                    <input v-model="initials.c16" type="text" placeholder="Initials" required :disabled="readonly">
                    <p>The Parties agree that neither shall seek a jury trial in any lawsuit, proceeding, counterclaim, or any other litigation based upon or arising out of this Agreement. The Parties hereby expressly waive their right to trial by jury.</p>
                </div>
                <div class="input-group -sbs">
                    <input v-model="initials.c17" type="text" placeholder="Initials" required :disabled="readonly">
                    <p>The Agent understands that they shall NOT, during the term of this Agreement and for one (1) years following the termination of this agreement, solicit Company’s employees, accounts, contractors, or corporate officers for employment on behalf of themselves or for any other entity.</p>
                </div>
                <p>IN WITNESS THEREFORE, the Parties have entered into this Agreement by their signatures below.</p>
                <div class="input-group">
                    <input v-model="initials.c18" type="text" placeholder="Type Your Full Name" required :disabled="readonly">
                    <input v-model="initials.c19" type="text" disabled required>
                </div>
                <footer>
                    <button v-if="readonly" type="button" class="btn-blue-light" @click="closeModal">Close</button>
                    <button v-else type="submit" class="btn-blue-light">Submit</button>
                </footer>
            </form>
		</div>
	</dialog>
</template>

<style scoped>
    dialog {
        top: 10%;
        max-height: 80%;
    }
    p {
        text-align: left;
        line-height: 1.4;
        margin-top: 0;
    }
    p._note {
        font-variation-settings: "slnt" 9,"wght" 400,"wdth" 100;
    }
    .input-group {
        input {
            height: auto;
            margin-bottom: 10px;
        }
    }
    .input-group.-sbs {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        height: auto;

        input {
            height: auto;
            width: 100px;
            margin-bottom: 0;
        }
        p {
            width: calc(100% - 120px);
        }
    }
</style>
